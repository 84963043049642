import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import leaseStore from ".//modules/lease.js";
import dataStore from "./modules/filter_data.js";

import Authentication from "./modules/authentication.js";
import BulkEdit from "./modules/bulk_edit.js";
import BillingProfile from "./modules/billing_profile.js";
import Notifications from "./modules/notifications.js";
import Properties from "./modules/properties.js";
import Status from "./modules/status.js";
import Navigation from "./modules/navigation.js";
import Todo from "./modules/todos.js";
import Call from "./modules/calls.js";
import Filter from "./modules/filter_data.js";
import Confirmation from "./modules/confirmation.js";
import Dashboards from "./modules/dashboards.js";
import Snackbar from "./modules/snackbar.js";
import OnBoarding from "./modules/on_boarding.js";
import Triggers from "./modules/triggers.js";
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";
import FilterData from "./modules/filter_data";
import TaskCenter from "./modules/task_center.js";
import ProfileTasks from "./modules/profile_tasks.js";
import Report from "./modules/report.js";
import RateManagement from "./modules/rate_management.js";
import RevenueManagement from "./modules/revenue_management.js"
import TaxProfile from "./modules/tax_profile.js";
import Settings from "./modules/settings.js";
import Payments from "./modules/payments.js";
import FeatureAndAmenities from "./modules/FeaturesAndAmenities";
import Accounting from "./modules/accounting.js";
import Documents from "./modules/documents.js";
import Utils from "./modules/utils.js";
import TenantProfile from "./modules/tenant_profile.js";
import TenantPaymentsApplication from "./modules/tenant_payments_application.js";
import CloseOfDay from "./modules/close_of_day.js";
import Contact from './modules/contact.js';
import Products from "./modules/Merchandise/products.js";
import Automation from "./modules/automation.js";
import AddMergeFields from "./modules/add_merge_fields.js";
import SendQuote from "./modules/send_quote.js";

// OPS
import OnBoardingNavigation from "./modules/onboarding/navigation.js";
import ReportLibrary from "./modules/report_library.js";

// Charm
import CharmCall from "./modules/CharmCall/index.js"
import CharmNotifications from "./modules/CharmNotifications/index.js";
import CharmSubscriptions from "./modules/CharmSubscriptions/index.js";
import GlobalNotifications from "./modules/global_notifications.js";

const bulkEditStore = new BulkEdit();
const billingProfileStore = new BillingProfile();
const dashboardsStore = new Dashboards();
const notificationsStore = new Notifications();
const filterStore = new FilterData();
const propertiesStore = new Properties();
const authenticationStore = new Authentication();
const statusStore = new Status();
const navigationStore = new Navigation();
const todoStore = new Todo();
const callStore = new Call();
const confirmationStore = new Confirmation();
const snackbarStore = new Snackbar();
const onBoardingStore = new OnBoarding();
const triggersStore = new Triggers();
const taskCenterStore = new TaskCenter();
const profileTasksStore = new ProfileTasks();
const rateManagementStore = new RateManagement();
const taxProfileStore = new TaxProfile();
const settingsStore = new Settings();
const paymentsStore = new Payments();
const tenantProfile = new TenantProfile();
const featureAndAmenitiesStore = new FeatureAndAmenities();
const revManStore = new RevenueManagement()
const tenantPaymentsApplicationStore = new TenantPaymentsApplication();
const closeOfDayStore = new CloseOfDay();

const reportStore = new Report();
const accountingStore = new Accounting();
const documentsStore = new Documents();
const utilsStore = new Utils();
const contactStore = new Contact();
const productStore = new Products();
const automationStore = new Automation();
const addMergeFieldsStore = new AddMergeFields()

const sendQuoteStore = new SendQuote();
//OPS
const onboardingTabsStore = new OnBoardingNavigation();
const reportLibraryStore = new ReportLibrary();

//Mini-Charm
const charmCallStore = new CharmCall();
const charmNotificationStore = new CharmNotifications();
const charmSubscriptionStore = new CharmSubscriptions();
const globalNotificationsStore = new GlobalNotifications();

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  //plugins: [vuexCookie.plugin],
  modules: {
    authenticationStore,
    bulkEditStore,
    billingProfileStore,
    statusStore,
    navigationStore,
    callStore,
    confirmationStore,
    leaseStore,
    filterStore,
    todoStore,
    dataStore,
    notificationsStore,
    propertiesStore,
    dashboardsStore,
    snackbarStore,
    onBoardingStore,
    triggersStore,
    taskCenterStore,
    profileTasksStore,
    reportStore,
    rateManagementStore,
    taxProfileStore,
    settingsStore,
    paymentsStore,
    featureAndAmenitiesStore,
    onboardingTabsStore,
    accountingStore,
    reportLibraryStore,
    documentsStore,
    utilsStore,
    contactStore,
    tenantProfile,
    revManStore,
    tenantPaymentsApplicationStore,
    closeOfDayStore,
    charmCallStore,
    charmNotificationStore,
    charmSubscriptionStore,
    globalNotificationsStore,
    productStore,
    automationStore,
    addMergeFieldsStore,
    sendQuoteStore
  },
  plugins: [
    createPersistedState({
      paths: [
        // 'authenticationStore',
        "statusStore",
        "navigationStore",
        "callStore",
        // 'confirmationStore',
        // 'leaseStore',
        'filterStore',
        'todoStore',
        'dataStore',
        'notificationsStore',
        'propertiesStore',
        'dashboardsStore',
        'snackbarStore',
        'onBoardingStore',
        'triggersStore',
        // 'taskCenterStore',
        'profileTasksStore',
        'reportStore',
        'rateManagementStore',
        'taxProfileStore',
        'settingsStore',
        'paymentsStore',
        'featureAndAmenitiesStore',
        'onboardingTabsStore',
        'accountingStore',
        "reportLbrary",
        "documentsStore",
        'TenantProfile',
        "utilsStore",
        'charmNotificationStore',
        'charmSubscriptionStore',
        'sendQuoteStore'
      ],
      storage: window.sessionStorage
      // storage: {
      //   getItem: (key) => Cookies.get(key),
      //   // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
      //   setItem: (key, value) => {
      //     Cookies.set(key, value, { expires: 3, secure: true });
      //   },
      //   removeItem: (key) => Cookies.remove(key),
      // }
    })
  ]
})
